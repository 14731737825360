import { FC, useEffect, useRef, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { LoadingScreen } from "../LoadingScreen";
import { areAllStepsCompleted, getExpectedStepNameByIndex } from "../../config/steps";
import { EConsultReasons } from "./EConsultReasons";
import { GetStartedSection } from "./GetStartedSection";
import { FAQ } from "./FAQ";
import { useMutation } from "@tanstack/react-query";
import updateUserById from "../../api-calls/updateUserById"
import { baseDomain } from "../../config/constants";
import { init, trackEvent } from "../../lib/AnalyticsService";
import mixpanel from 'mixpanel-browser';
import { useAppStepTracker } from "../../context/AppStepTracker";
import {getRedirectUrl} from "../../lib/redirect";

export const ThankYouScreen: FC = () => {
    const { data, isLoading } = useCurrentUser();
    const { lastStepCompleted } = useAppStepTracker();
    const { mutate: completeOnboarding } = useMutation({mutationFn: (_: null) => updateUserById(data?.data.id, {onboarding_complete: true})})
    // Using useMemo to memoize userData and avoid unnecessary computations on re-renders
    const userData = useMemo(() => data?.data, [data]);
    const mutated = useRef<boolean>(false);

    // Go to beginning if steps haven't been completed yet or go to following step to complete
    const goToExpectedRoute = () => lastStepCompleted === null ? '' : 
      getExpectedStepNameByIndex(lastStepCompleted);

    /**
     * Initialize Mixpanel and set 'onboarding_complete' property for user.
     * @param {object} user - The user object containing information for Mixpanel initialization.
     */
    const initializeMixpanel = (user: object) => {
        try {
            // Check if mixpanel is defined and has a 'people' property
            if (mixpanel?.people) {
                // If 'mixpanel.people' is available, set 'onboarding_complete' property
                mixpanel.people.set({
                    onboarding_complete: true,
                });
            } else {
                // If 'mixpanel.people' is not available (for example, when refreshing the page),
                // initialize Mixpanel with the user and set 'onboarding_complete'
                init(user);
                mixpanel.people.set({
                    onboarding_complete: true,
                });
            }
        } catch (e) {
            // Handle any errors during Mixpanel initialization
            console.error(e);
            console.warn("Something went wrong with Mixpanel initialization.");
        }
    };

    useEffect(() => {
        if (userData) {
            if( userData.onboarding_complete ) {
                window.location.href = getRedirectUrl(userData);
            } else if (areAllStepsCompleted(lastStepCompleted) && !mutated.current) {
                mutated.current = true;
                completeOnboarding(null);
                initializeMixpanel(userData);
                trackEvent('Thank You Screen', {
                    'timestamp': new Date().toISOString(),
                });
            }
        }
    }, [userData])  

    return (
        isLoading || userData.onboarding_complete ? <LoadingScreen /> : (
            !areAllStepsCompleted(lastStepCompleted) ? 
              <Navigate to={`/${goToExpectedRoute()}`}/> : 
              (
                <div className="flex flex-col h-screen">
                    <GetStartedSection />
                    <EConsultReasons reasons={
                        [
                            { 
                                specialty: "Dermatology",
                                reasons: [
                                    "Acne",
                                    "Alopecia",
                                    "Biopsy Management",
                                    "Lesion",
                                    "Psoriasis",
                                    "Rash"
                                ]
                            },
                            {
                                specialty: "Cardiology",
                                reasons: [
                                    "Abnormal EKG",
                                    "EKG Overread",
                                    "Hyperlipidemia",
                                    "Hypertension",
                                    "Medication Management",
                                    "Pre-operative Risk",
                                    "Stress Test Interpretation"
                                ]
                            },
                            {
                                specialty: "Hematology",
                                reasons: [
                                    "Abnormal Labs",
                                    "Anemia",
                                    "Coagulopathy",
                                    "Eosinophilia",
                                    "Leukocytosis",
                                    "Leukopenia",
                                    "Neutropenia"
                                ]
                            },
                            {
                                specialty: "Orthopedic Surgery",
                                reasons: [
                                    "Bursitis",
                                    "Elbow Pain",
                                    "Extremity Fracture",
                                    "Hip Pain",
                                    "Low Back Pain",
                                    "Shoulder Pain"
                                ]
                            },
                            {
                                specialty: "Pediatric Endocrinology",
                                reasons: [
                                    "Delayed or Precocious Puberty",
                                    "Diabetes Management",
                                    "Hyperthyroidism",
                                    "Short Stature"
                                ]
                            },
                            {
                                specialty: "ENT w/ Pediatric Focus",
                                reasons: [
                                    "Ear Infection",
                                    "Epistaxis",
                                    "Nasal Congestion"
                                ]
                            },
                            {
                                specialty: "Pediatric Gastroenterology",
                                reasons: [
                                    "Abdominal Pain",
                                    "Constipation",
                                    "Diarrhea",
                                    "Elevated liver function tests"
                                ]
                            }
                        ]
                    }/>
                    <FAQ questions={[
                        {
                            question: 'What is AristaMD, and how does the program help providers?',
                            answer: (
                                <>
                                    <p className="pb-4">AristaMD’s eConsult platform is a physician-to-physician, asynchronous telehealth solution that empowers primary care providers. The platform gives providers the ability to conduct electronic consults with a panel of on-demand board-certified specialists who will respond within 24 hours. eConsults are an invaluable tool in any primary care practice to manage your patients and ensure they get the timely, specialist-guided treatment they need. More than 70% of routine specialist referrals can be safely treated by primary care providers with the guidance of specialty eConsults.</p>
                                    <p className="font-bold">The AristaMD eConsult platform:</p>
                                    <ul className="list-disc list-outside pt-2 pl-12">
                                        <li className="mb-2">Cost-effective alternative for patient face-to-face specialty visits (non-procedural, non-urgent, and not PT or OT)</li>
                                        <li className="mb-2">A tool to alleviate wait times for specialty care, allowing the patients to stay in the primary care setting</li>
                                        <li className="mb-2">Eliminates the need to travel to a specialty visit</li>
                                        <li className="mb-2">Validation of treatment plan and need to refer the patient</li>
                                        <li className="mb-2">Treatment questions or medication regimen confirmation</li>
                                        <li className="mb-2">Provides a second opinion</li>
                                    </ul>
                                </>
                            )
                        },
                        {
                            question: 'What is AristaMD, and how does the program help providers help patients?',
                            answer: (
                               <>
                                    <p className="pb-4">As you are aware, for many patients, access to specialist care is a significant obstacle to receiving the best care. With wait times stretching weeks or months, long delays can compound care compliance and result in condition decline. Many specialist physicians are also out-of-network, resulting in significant out-of-pocket costs for patients. And forgoing specialist care can result in significant gaps in care.</p>
                                    <p>By utilizing eConsults, PCPs can send patient information, such as medical histories, images, and test results, via a secure online platform to board-certified specialists who will weigh in on cases remotely. Using eConsults from AristaMD, patients can receive specialist-backed treatment plans within 24 hours. Plus, eConsults are more affordable, bypass the need for patients to take time out of work or travel, and support primary care providers in delivering care in a completely virtual manner.</p>
                               </>
                            )
                        },
                        {
                            question: 'How do eConsults save money?',
                            answer: (
                                <>
                                    <p>More than $75 billion is spent each year on specialist referral costs in the US.</p>
                                    <ul className="list-disc list-outside pt-2 pl-12">
                                        <li className="mb-2">eConsults contribute to an 11-12% reduction in emergency department visits.</li>
                                        <li className="mb-2">20-30% of routine specialist referrals are for clinical issues that can be safely treated by primary care providers (source: AristaMD internal data, 2018).</li>
                                        <li className="mb-2">The average specialist referral results in 4 specialist visits. eConsult referrals prevent unnecessary specialist visits, duplication of care, and network leakage.</li>
                                        <li className="mb-2">eConsults prevent fragmentation of care as the primary care provider remains in control of the patient’s treatment plan.</li>
                                    </ul>
                                </>
                            )
                        },
                        {
                            question: 'How do providers perform eConsults (i.e., process, turnaround time)?',
                            answer: (
                              <>
                                <p className="pb-4">We have two workflows for the eConsult process. One is a standard workflow, while the other incorporates an AristaMD <a className="text-indigo-300" href="https://www.aristamd.com/literature/expert-referral-specialist-team-curate-and-route-virtual-physician-to-physician-consultations/">registered nurse</a>, who curates the eConsult on behalf of the provider.</p>
                                <p>The standard workflow performed by the PCP or referral coordinator takes less than five minutes. eConsults curated by AristaMD registered nurses require only about a minute of the PCP’s time.&nbsp;</p>
                              </>
                            )
                        },
                        {
                            question: 'What data does AristaMD collect from eConsults that will be shared with clinics and case managers?',
                            answer: (
                                <>
                                <p>The following data is collected and compiled into detailed, yet easy to follow, standard reporting dashboards:</p>
                                <ul className="list-disc list-outside pt-2 pl-12">
                                    <li className="mb-2">eConsult volume</li>
                                    <li className="mb-2">Efficacy (whether the eConsult replaced a visit and/or influenced the care plan for the patient)</li>
                                    <li className="mb-2">New diagnosis (whether the eConsult resulted in a new diagnosis for the patient)</li>
                                    <li className="mb-2">Primary care provider satisfaction</li>
                                    <li className="mb-2">Patient benefits including travel time saved, appointment waiting time saved and cost savings</li>
                                </ul>
                                </>
                            )
                        }
                    ]}/>
                </div>
            )
        )
    )
}