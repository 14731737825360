import {baseDomain} from "../config/constants";

/**
 * @param {object} userData
 * @return {string} redirect url
 */
export function getRedirectUrl(userData) {
  let redirectUrl = sessionStorage.getItem('onboarding_redirect_to') ?? `https://app${baseDomain}`;
  // If user has access to v2 and the redirect is not a v2 url, proceed to change it to v2
  if(userData.v2_uri && !redirectUrl.match(`^${userData.v2_uri}`)) {
    // Additionally, try to parse eConsult id (e.g. /econsults/:id) and add it to the redirect
    const matches = redirectUrl.match(/\/econsults\/(\d+)(\?.*)?$/);
    redirectUrl = userData.v2_uri + (matches?.[1] ? `consults/${matches?.[1]}` : '');
  }
  return redirectUrl;
}
